import React, { useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";

import "../styles/pages/Support.scss";

import Layout from "../components/Layout";
import Seo from "../components/Seo";

export default function Support({ location: { pathname } }) {
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout pathname={pathname}>
      <Seo title={intl.formatMessage({ id: "support-title" })} />
      <section className="support">
        <h1 className="support__title">{intl.formatMessage({ id: "support-subtitle" })}</h1>
        <h2 className="support__question">{intl.formatMessage({ id: "support-question1" })}</h2>
        <p className="support__answer">
          {intl.formatMessage({ id: "support-answer1" })}
        </p>
        <h2 className="support__question">{intl.formatMessage({ id: "support-question2" })}</h2>
        <p className="support__answer">
          {intl.formatMessage({ id: "support-answer2" })}
        </p>
        <h2 className="support__question">{intl.formatMessage({ id: "support-question3" })}</h2>
        <p className="support__answer">
          {intl.formatMessage({ id: "support-answer3" })}
        </p>
        <h2 className="support__question">{intl.formatMessage({ id: "support-question4" })}</h2>
        <p className="support__answer">
          {intl.formatMessage({ id: "support-answer4" })}
        </p>
      </section>
    </Layout>
  );
}
